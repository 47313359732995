
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getARAgingSummary } from "@/api/reports.api";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: string;
  description: string;
}

export interface Data {
  model: any;
}
export default defineComponent({
  name: "AgingSummaryReport",
  props: ["patientId"],
  components: {},
  setup() {
    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      model: null,
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      const res = await getARAgingSummary({});
      data.model = res;
    });

    return {
      organizationId,
      data,
    };
  },
});
